<template>
  <div>
    <div class="home">
      <el-carousel height="49vw" class="hidden-xs-only">
        <el-carousel-item v-for="(item, index) in t.carousel" :key="index">
          <div class="carousel-box">
            <img :src="item.logo" alt />
            <div class="bg"></div>
            <div class="label">
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="480px" class="hidden-sm-and-up">
        <el-carousel-item v-for="(item, index) in t.carousel" :key="index">
          <div class="carousel-box">
            <img :src="item.logo" alt />
            <div class="bg"></div>
            <div class="label">
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="aboutMe">
        <div class="content">
          <div class="header">
            <div class="title">{{ t.about.title }}</div>
            <div class="desc">{{ t.about.desc }}</div>
          </div>
          <div class="imgBox1 hidden-sm-and-down">
            <img :src="t.about.list[0].pic" alt />
          </div>
          <div class="imgBox2 hidden-sm-and-down">
            <img :src="t.about.list[1].pic" alt />
          </div>
          <el-row>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="box">
                <div class="title">{{ t.about.list[0].title }}</div>
                <div class="desc">{{ t.about.list[0].desc }}</div>
                <router-link :to="t.about.list[0].link" tag="div" class="link">
                  {{ t.about.list[0].linkText }}
                  <i class="el-icon-right"></i>
                </router-link>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div style="height:1px"></div>
              <div class="imgBox3 hidden-md-and-up">
                <img :src="t.about.list[0].pic" alt />
              </div>
            </el-col>
            <el-col :span="24"></el-col>

            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div style="height:1px"></div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="box">
                <div class="title">{{ t.about.list[1].title }}</div>
                <div class="desc">{{ t.about.list[1].desc }}</div>
                <router-link :to="t.about.list[1].link" tag="div" class="link">
                  {{ t.about.list[1].linkText }}
                  <i class="el-icon-right"></i>
                </router-link>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
              <div style="height:1px"></div>
              <div class="imgBox4 hidden-md-and-up">
                <img :src="t.about.list[1].pic" alt />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="screen product" ref="dbs">
        <div class="dbs-1"></div>
        <div class="dbs-2">
          <div class="dbs2-bg screen" :style="{ top: dbsTop + 'px' }">
            <img src="/img/dbs2.png" alt />
          </div>
          <div class="content">
            <div class="title">{{ t.product.title }}</div>
            <div class="desc">{{ t.product.desc }}</div>
            <div class="remark">{{ t.product.remark }}</div>
            <router-link :to="t.product.link" tag="div" class="link">
              了解更多
              <i class="el-icon-right"></i>
            </router-link>
          </div>
        </div>
      </div>

      <div class="screen build">
        <div class="bg">
          <img :src="t.build.bg" alt />
        </div>

        <div class="content">
          <div class="content-body">
            <div class="title">{{ t.build.title }}</div>
            <div class="desc">{{ t.build.desc }}</div>
            <div class="remark">{{ t.build.remark }}</div>
          </div>
        </div>
      </div>
      <div style="background:#fff">
        <div class="third">
          <p class="title">为什么选择搭把手</p>
        </div>
        <el-row class="third" style="max-width: 1720px;width:95%">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="box">
              <img src="/img/image_quality.png" alt />
              <div class="content">
                <div class="title">品质无忧</div>
                <div class="desc">专业成就卓越，从细节出发，体贴不同的需求；站在专业的角度，完成自我的优化，对品质的坚持，是搭把手矢志不渝地对顾客的承诺。</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="box">
              <img src="/img/image_price.png" alt />
              <div class="content">
                <div class="title">价格优势</div>
                <div class="desc">我司以诚信经营为原则，以客户满意为目标，并不断地研究、开发、生产出物美价廉的产品。</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="box">
              <img src="/img/image_service.png" alt />
              <div class="content">
                <div class="title">售后无忧</div>
                <div class="desc">我司有完善的售后服务体系和质量保证体系，专门建立了一支专业技术服务队伍，负责技术支持和对客户的服务工作，随时解决用户的问题。</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div style="height: 5vw"></div>
      </div>
      <!-- <div class="fourth">
        <div class="content">
          <p class="title">联系我们</p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <p class="desc">搭把手致力于描绘“碧海蓝天”的未来画卷，始终为环保事业、城市的可持续发展助力。</p>
          <br />
          <br />
          <router-link to="/contact" tag="div" class="contactBtn link">
            联系我们
            <i class="el-icon-right"></i>
          </router-link>
        </div>
        <div class="imgBox">
          <img src="/img/wl.png" alt />
        </div>
      </div>-->
    </div>
    <div class="screen dbs1-bg">
      <img src="/img/dbs.png" alt />
    </div>
  </div>
</template>

<script>
const data = {
  carousel: [
    {
      title: "智赋未来 /",
      desc: "智能回收设备与5G微基站的融合创新",
      logo: "./img/VCG211296781238.png",
      link: "",
    },
  ],
  product: {
    title: "标准版智能回收箱",
    desc: "不仅是垃圾回收",
    remark:
      "工业控制技术  /  智能检测及称重技术  /  智能视觉判定技术  /  物联网  /  云技术",
    link: "/product",
  },
  about: {
    title: "关于我们",
    desc: "循环再生，让地球更年轻",
    list: [
      {
        title: "我们的使命",
        desc: "搭把手致力于描绘“碧海蓝天”的未来画卷 始终为环保事业、城市的可持续发展助力",
        pic: "/img/image_ourduty.png",
        linkText: "访问详情介绍",
        link: "/about",
      },
      {
        title: "智能环保领域领导者",
        desc: "『搭把手』拍了拍你说：一起垃圾分类吗？使新型科技造福于环境，搭把手一直在前进",
        pic: "/img/VCG41N1196161943.png",
        linkText: "访问最新消息",
        link: "/",
      },
    ],
  },
  build: {
    bg: "/img/image_JiangshanRecycleCenter.png",
    title: "鄞州区姜山再生资源分拣中心 / ",
    desc: "运作模式全国首创",
    remark:
      "姜山再生资源分拣中心是宁波首个“垃圾分类+资源回收”试点配套综合分拣中心。一车车运进来的可回收垃圾卸车后，纸箱等高价值回收物先被压缩打包后堆在指定位置。破旧沙发、床垫等经过分解，对拆出的金属、海绵、木头、破布、棕榈等分门别类进行回收处理，比如破布，被相关企业收购后可制成纤维，再用于生产与生活。",
  },
};

export default {
  name: "Home",
  data() {
    return {
      t: data,
      dbsTop: 0,
    };
  },
  mounted() {
    document
      .querySelector("#app")
      .addEventListener("scroll", this.scroll, false);
    window.onresize = this.scroll;
  },
  beforeDestroy() {
    document.querySelector("#app").removeEventListener("scroll", this.scroll);
    window.onresize = null;
  },
  methods: {
    scroll() {
      let scrollTop = document.querySelector("#app").scrollTop;
      let dbsTop = this.getElementToPageTop(this.$refs.dbs);
      this.dbsTop = scrollTop - dbsTop - this.$refs.dbs.offsetHeight * 0.5;
    },
    getElementToPageTop(el) {
      if (el.parentElement) {
        return this.getElementToPageTop(el.parentElement) + el.offsetTop;
      }
      return el.offsetTop;
    },
  },
};
</script>
<style lang="scss">
.home .el-carousel ul {
  left: 10vw;
  transform: translateX(0);
  bottom: 6rem;
  .el-carousel__button {
    width: 30px;
    height: 4px;
    opacity: 0.8;
  }
}
</style>
<style lang="scss" scoped>
.link {
  cursor: pointer;
  display: inline-block;
  padding: 15px 29px;
  font-size: 14;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #ffffff;
  border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
  box-sizing: border-box;
  border-radius: 25px;
  margin-top: 5.7rem;
  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.1);
  }
}

.home {
  width: 100vw;
  position: relative;
  z-index: 3;
}
.dbs1-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  img {
    width: auto;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.carousel-box {
  width: 100%;
  height: 100%;
  position: relative;
  & > .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45%;
    background: linear-gradient(
      180deg,
      rgba(0, 9, 34, 0.0001) -2.08%,
      rgba(0, 9, 34, 0.510883) 35.69%,
      #000922 100%
    );
  }
  img {
    width: 100%;
    height: 100%;
  }
  .label {
    position: absolute;
    left: 50%;
    bottom: 0;
    padding-bottom: 10vw;
    padding-top: 3vw;
    width: 80%;
    max-width: 1520px;
    transform: translateX(-50%);
    box-sizing: border-box;

    font-family: PingFangSC;
    color: #ffffff;
    .title {
      font-weight: 100;
      font-size: 5.2vw;
      line-height: 1.2;
      letter-spacing: 1px;
    }
    .desc {
      font-weight: 100;
      font-size: 4.1vw;
      line-height: 1.2;
      letter-spacing: 1px;
    }
  }
}
.screen {
  width: 100vw;
  height: 100vh;
}

.aboutMe {
  width: 100%;
  background: #000922 url("/img/background_weilan.png") 0 0 / 100% auto
    no-repeat;
  position: relative;
  .imgBox1 {
    width: 60vw;
    height: 30vw;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 13.6vw;
    z-index: 2;
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }
  .imgBox2 {
    width: 60vw;
    height: 30vw;
    position: absolute;
    overflow: hidden;
    left: 0;
    bottom: 11.6vw;
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
    z-index: 1;
  }

  .imgBox3,
  .imgBox4 {
    width: 90vw;
    height: 45vw;
    overflow: hidden;
    left: 0;
    bottom: 11.6vw;
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
    z-index: 1;
  }

  .content {
    width: 80%;
    max-width: 1520px;
    margin: 0 auto;
    padding-bottom: 12rem;
    .header {
      border-top: 1px solid rgba($color: #000922, $alpha: 1);
      padding: 5.6rem 0 8rem;
      & > .title {
        font-size: 7rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: 200;
      }
      & > .desc {
        font-size: 2.2rem;
        line-height: 1.1;
        letter-spacing: 0.733333px;
        color: #288eff;
        margin-top: 2rem;
      }
    }

    .box {
      padding: 5.9vw 0 9.9vw;
      .title {
        font-size: 4rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: 200;
      }
      .desc {
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 1px;
        color: #ffffff;
        margin-top: 4rem;
        font-weight: 200;
      }
    }
  }
}

.product {
  height: 200vh;
  & > div {
    width: 100%;
    height: 50%;
  }
  .dbs-2 {
    background: #000922;
    position: relative;
    overflow: hidden;
    .dbs2-bg {
      position: absolute;
      left: 0;
      top: -50%;
      z-index: 1;
      img {
        width: auto;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .content {
      width: 80%;
      max-width: 1520px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      box-sizing: border-box;
      .title {
        cursor: pointer;
        margin-top: 1.45vw;
        font-size: 1.1vw;
        line-height: 1.1;
        letter-spacing: 0.7px;
        color: #288eff;
      }
      .desc {
        margin-top: 5rem;
        font-size: 7rem;
        line-height: 1.4;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: 200;
      }
      .remark {
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 1px;
        color: #ffffff;
        margin-top: 0.4rem;
      }
    }
  }
}

.build {
  overflow: hidden;
  position: relative;
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    img {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 15rem 0 9rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: linear-gradient(
      180deg,
      rgba(0, 9, 34, 0.0001) -2.08%,
      rgba(0, 9, 34, 0.510883) 35.69%,
      #000922 100%
    );
    .content-body {
      width: 80%;
      margin: 0 auto;
      max-width: 1520px;
      .title {
        font-size: 8rem;
        line-height: 1.4;
        letter-spacing: 0.8px;
        color: #ffffff;
        font-weight: 200;
      }

      .desc {
        font-size: 6rem;
        line-height: 1.4;
        letter-spacing: 0.8px;
        color: #ffffff;
        font-weight: 200;
      }
      .remark {
        margin-top: 6rem;
        font-size: 2rem;
        line-height: 2;
        /* or 200% */
        letter-spacing: 1px;
        color: #ffffff;
      }
    }
    .getter {
      width: 16rem;
    }
  }
}

.third {
  background-color: #fff;
  width: 80%;
  max-width: 1520px;
  margin: 0 auto;
  .title {
    font-size: 7rem;
    line-height: 1.2;
    letter-spacing: 1px;
    color: #000000;
    padding-top: 8rem;
    font-weight: 200;
    box-sizing: border-box;
  }
  .box {
    width: 90%;
    margin: 7rem auto;
    position: relative;
    img {
      width: 100%;
    }
    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 85%;
      background: linear-gradient(
        180deg,
        rgba(0, 9, 34, 0.0001) -2.08%,
        rgba(0, 9, 34, 0.510883) 35.69%,
        #000922 100%
      );
      box-sizing: border-box;
      padding: 30% 5.8rem 0;
      .title {
        font-size: 4rem;
        line-height: 4.7rem;
        letter-spacing: 1px;
        color: #ffffff;
      }
      .desc {
        font-size: 2rem;
        line-height: 4rem;
        letter-spacing: 1px;
        color: #ffffff;
        margin-top: 3.6rem;
      }
    }
  }
}

.fourth {
  background-color: #fff;
  position: relative;
  padding-top: 5vw;
  .content {
    width: 350px;
    background: #0670d8;
    box-sizing: border-box;
    padding: 60px 40px;
    position: absolute;
    right: 10vw;
    top: 0vw;
    z-index: 2;
    .title {
      font-size: 30px;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #ffffff;
      position: relative;
      font-weight: 200;
      &::after {
        content: "";
        display: block;
        width: 3rem;
        height: 2px;
        border-radius: 1px;
        position: absolute;
        top: -10px;
        left: 0;
        background-color: #fff;
      }
    }
    .desc {
      font-size: 20px;
      line-height: 2;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 200;
    }
  }
  .imgBox {
    position: relative;
    z-index: 1;
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
  .contactBtn {
  }
}

@media screen and (max-width: 1400px) {
  .fourth {
    padding-top: 10vw;
  }
}

@media screen and (max-width: 1200px) {
  .fourth {
    padding-top: 15vw;
  }
  .dbs1-bg img {
    left: 20vw;
    right: auto;
  }
  .product .dbs-2 .dbs2-bg img {
    left: 20vw;
    right: auto;
  }
}
@media screen and (max-width: 1100px) {
  .fourth {
    padding-top: 20vw;
  }
}

@media screen and (max-width: 992px) {
  .fourth {
    padding-top: 25vw;

    .imgBox {
      height: 40vw;
      img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .third .title {
    text-align: center;
    text-indent: 0;
    font-size: 5rem;
  }

  .fourth {
    padding: 0;
    .content {
      position: relative;
      width: 100%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 20px;
      bottom: 0;
      z-index: 2;
      text-align: center;
      .title::after {
        display: none;
      }
    }
    .imgBox {
      position: relative;
      top: 0px;
      padding: 0;
      width: 100%;
      height: 100vw;
      overflow: hidden;
      img {
        height: 100%;
        width: auto;
        position: absolute;
        top: 0%;
        left: -150%;
        transform: translate(0, 0);
      }
    }
  }

  .carousel-box {
    position: relative;
    img {
      height: 100%;
      width: auto;
      position: absolute;
      right: -10%;
      top: 0;
    }
    .bg {
      height: 65%;
    }
    .label {
      padding: 0;
      right: auto;
      top: 250px;
      font-size: 24px;
      .title {
        font-size: 36px;
        line-height: 1.4;
      }
      .desc {
        font-size: 24px;
        line-height: 1.4;
      }
    }
  }
  .product .dbs-2 .content .title {
    font-size: 16px;
  }
}
</style>
