export default {
  menus: {
    hs: {
      name: "可回收智能投递箱（柜）",
      desc: "不仅是垃圾回收",
      remark: "工业控制技术  /  智能检测及称重技术  /  智能视觉判定技术  /  物联网  /  云技术",
      pic: "/img/hs.png",
      pic2: "/img/hs2.png",
      structure: [
        {
          label: "钣金结构",
          value: "内含主体柜，防雨顶棚"
        },
        {
          label: "传动机构",
          value: "含各仓室的投物门开合机构，饮料瓶传送机构"
        },
        {
          label: "控制系统",
          value: "含硬件控制柜，照明系统，排风系统，满仓检测系统，称重系统，电子锁"
        },
        {
          label: "安卓系统",
          value: "含安卓主机和触摸屏"
        }
      ],
      module: [
        {
          logo: "/img/module/icon_weigh.png",
          title: "智能称重"
        },
        {
          logo: "/img/module/icon_alarm.png",
          title: "智能预警"
        },
        {
          logo: "/img/module/icon_monitor.png",
          title: "摄像监控"
        },
        {
          logo: "/img/module/icon_hand.png",
          title: "主动防夹手"
        },
        {
          logo: "/img/module/icon_water proof.png",
          title: "户外防水"
        },
        {
          logo: "/img/module/icon_free.png",
          title: "自由组合搭配"
        },
        {
          logo: "/img/module/icon_kid.png",
          title: "儿童模式"
        },
        {
          logo: "/img/module/icon_charge.png",
          title: "光伏充电"
        },
        {
          logo: "/img/module/icon_light.png",
          title: "智能照明"
        },
        {
          logo: "/img/module/icon_wind.png",
          title: "通风系统"
        },
        {
          logo: "/img/module/icon_interaction.png",
          title: "人机交互"
        },
        {
          logo: "/img/module/icon_Chinese&English.png",
          title: "中英文模式"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内/室外"
        },
        {
          label: "额定功率",
          value: "200W"
        },
        {
          label: "空闲功率",
          value: "70W"
        },
        {
          label: "工作电源",
          value: "单相，AC220V-AC240V，50HZ"
        },
        {
          label: "通讯模式",
          value: "4G，全网通"
        },
        {
          label: "计费模式",
          value: "饮料瓶：视觉系统判别，计数。其余：称重计量"
        },
        {
          label: "计量精度",
          value: "分辨率30g，单次投递重量低于60g不计费"
        },
        {
          label: "最大容量",
          value: "称重上限：300kg<br/>投瓶上限：230个<br/>最大分项容量：550L"
        }
      ],
      plan: "/img/0-2.png"
    },
    jwb: {
      name: "巨无霸-全品类智能回收站",
      desc: "厨余&其他垃圾智能前端管理设备<br/>+<br/>全品类智能回收站",
      remark: "工业控制技术  /  智能检测及称重技术  /  智能视觉判定技术  /  物联网  /  云技术",
      pic: "/img/jwb.png",
      pic2: "/img/jwb2.png",
      structure: [
        {
          label: "钣金结构",
          value: "全金属框架结构"
        },
        {
          label: "传动机构",
          value: "传动系统"
        },
        {
          label: "控制系统",
          value: "自动化控制系统"
        },
        {
          label: "安卓系统",
          value: "安卓系统及HM交互系统"
        },
        {
          label: "其他系统",
          value: "自动生化处理系统及自动消防系统等"
        }
      ],
      module: [
        {
          logo: "/img/module/icon_weigh.png",
          title: "智能称重"
        },
        {
          logo: "/img/module/icon_alarm.png",
          title: "智能预警"
        },
        {
          logo: "/img/module/icon_monitor.png",
          title: "摄像监控"
        },
        {
          logo: "/img/module/icon_hand.png",
          title: "主动防夹手"
        },
        {
          logo: "/img/module/icon_water proof.png",
          title: "户外防水"
        },
        {
          logo: "/img/module/icon_kid.png",
          title: "儿童模式"
        },
        {
            logo: "/img/module/icon_interaction.png",
            title: "人机交互"
        },
        {
            logo: "/img/module/icon_Chinese&English.png",
            title: "中英文模式"
        },
        {
          logo: "/img/module/icon_light.png",
          title: "智能照明"
        },
        {
          logo: "/img/module/icon_wind.png",
          title: "通风系统"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内/室外"
        },
        {
          label: "额定功率",
          value: "15kW"
        },
        {
          label: "通讯模式",
          value: "4G，全网通 "
        },
        {
          label: "工作电源",
          value: "AC380V，50HZ"
        },
        {
          label: "计量精度 ",
          value: "分辨率30g，单次投递重量低于60g不计费"
        },
        {
          label: "最大容量",
          value: "其他垃圾：三个240L垃圾桶<br/>投瓶数：400个<br/>称重上限：150kg<br/>厨余：200kg/天量"
        },
         {
          label: "计费模式",
          value: "饮料瓶：视觉系统判别，计数。其余：称重计量"
        }
      ],
      plan: "/img/jwb3.png"
    },
    dt: {
      name: "智能单体投瓶机",
      desc: "随手投递，轻松环保",
      remark: "工业控制技术  /  智能检测  /  智能视觉判定技术  /  物联网  /  云技术",
      pic: "/img/dt1.png",
      pic2: "/img/dt2.png",
      structure: [
        {
          label: "钣金结构",
          value: "内含主体柜"
        },
        {
          label: "传动机构",
          value: "含投物门开合机构，饮料瓶传送机构"
        },
        {
          label: "控制系统",
          value: "含硬件控制柜，照明系统，排风系统，电子锁，视觉系统"
        },
        {
          label: "安卓系统",
          value: "含安卓主机和触摸屏"
        }
      ],
      module: [
        {
            logo: "/img/module/icon_alarm.png",
            title: "智能预警"
        },
        {
          logo: "/img/module/icon_wind.png",
          title: "通风系统"
        },
        {
            logo: "/img/module/icon_interaction.png",
            title: "人机交互"
        },
        {
            logo: "/img/module/icon_Chinese&English.png",
            title: "中英文模式"
        },
        {
            logo: "/img/module/icon_kid.png",
            title: "儿童模式"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内"
        },
        {
          label: "额定功率",
          value: "100W"
        },
        {
          label: "空闲功率",
          value: "70W"
        },
        {
          label: "工作电源",
          value: "单相，AC220V-AC240V，50HZ"
        },
        {
          label: "通讯模式 ",
          value: "4G，全网通"
        },
        {
          label: "计费模式",
          value: "饮料瓶：视觉系统判别，计数"
        },
         {
          label: "最大容量",
          value: "投瓶上限：400个"
        }
      ],
      plan: "/img/dt3.png"
    },
    cy: {
      name: "厨余/其他垃圾智能(AI)投递箱（柜）",
      desc: "健康生活，从分类开始",
      remark: "工业控制技术  /  智能检测及称重技术  /  物联网  /  云技术",
      pic: "/img/cy.png",
      pic2: "/img/cy2.png",
      structure: [
        {
          label: "钣金结构",
          value: "含1台主体柜包含两仓厨余回收柜，2台含两仓其他垃圾回收柜，防雨顶棚"
        },
        {
          label: "传动机构",
          value: "含各仓室投物门开合机构"
        },
        {
          label: "控制系统",
          value: "含硬件控制柜，照明系统，排风系统，满仓检测系统，称重系统，电子锁，除臭系统，扫码器，人体感应检测体统"
        },
        {
          label: "安卓系统",
          value: "含安卓主机和触摸屏"
        },
        {
          label: "监控系统",
          value: "半球形高清网络摄像头"
        }
      ],
      module: [
        {
          logo: "/img/module/icon_weigh.png",
          title: "智能称重"
        },
        {
          logo: "/img/module/icon_alarm.png",
          title: "智能预警"
        },
        {
          logo: "/img/module/icon_monitor.png",
          title: "摄像监控"
        },
        {
          logo: "/img/module/icon_hand.png",
          title: "主动防夹手"
        },
        {
          logo: "/img/module/icon_water proof.png",
          title: "户外防水"
        },
        {
          logo: "/img/module/icon_free.png",
          title: "自由组合搭配"
        },
        {
            logo: "/img/module/icon_light.png",
            title: "智能照明"
        },
        {
            logo: "/img/module/icon_wind.png",
            title: "通风系统"
        },
        {
            logo: "/img/module/icon_interaction.png",
            title: "人机交互"
        },

        {
          logo: "/img/module/icon_o3.png",
          title: "臭氧除臭"
        },
        {
          logo: "/img/module/icon_AI.png",
          title: "AI识别"
        },
        {
          logo: "/img/module/icon_voice.png",
          title: "语音提示"
        },
        {
          logo: "/img/module/icon_mouse.png",
          title: "超声波驱鼠"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内/室外"
        },
        {
          label: "额定功率",
          value: "500W"
        },
        {
            label: "通讯模式",
            value: "4G，全网通"
        },
        {
          label: "工作电源",
          value: "单相，AC220V-AC240V，50HZ"
        },
        {
          label: "回收容量",
          value: "单仓标准环卫垃圾桶，240L"
        },
        {
          label: "计量模式",
          value: "称重计量，最大300kg，分辨率100g"
        },
        {
          label: "除臭模式",
          value: "臭氧除臭"
        },
        {
          label: "清运模式",
          value: "智能满仓提醒，人工清运"
        },
        {
          label: "语音宣导",
          value: "当用户靠近智能回收箱时，系统会自动播报语音宣导内容"
        },
        {
          label: "监控与安全",
          value: "24小时监控"
        },
        {
          label: "视觉识别",
          value: "厨余垃圾破袋，识别，打分"
        }
      ],
      plan: "/img/cy3.png" 
    },
    gb: {
      name: "智能过磅机",
      desc: "智能称重，科学管理",
      remark: "管理端  /  智能刷卡机  /  打印机  /  高清摄像头",
      pic: "/img/gb.png",
      pic2: "/img/gb2.png",
      structure: [
        {
          label: "钣金结构",
          value: "含主体柜"
        },
        {
          label: "安卓系统",
          value: "含安卓主机和触摸屏"
        },
        {
          label: "附件",
          value: "称重仪表"
        }
      ],
      module: [
        {
            logo: "/img/module/icon_interaction.png",
            title: "人机交互"
        },  
        {
          logo: "/img/module/icon_card.png",
          title: "刷卡识别"
        },
        {
          logo: "/img/module/icon_face.png",
          title: "人脸识别"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内/室外"
        },
        {
          label: "额定功率",
          value: "100W"
        },
        {
          label: "空闲功率",
          value: "70W"
        },
        {
          label: "工作电源",
          value: "单相，AC220V-AC240V，50HZ"
        },
        {
          label: "通讯模式",
          value: "4G，全网通"
        },
        {
          label: "适用场景",
          value: "地磅单一品类，小秤多品类，上门回收，废品入库"
        }
      ],
      plan: "/img/gb3.png"
    },
    fd: {
      name: "智能发袋机",
      desc: "智能发袋，垃圾溯源",
      remark: "工业控制技术  /  智能检测  /  物联网  /  云技术",
      pic: "/img/fd.png",
      pic2: "/img/fd2.png",
      structure: [],
      module: [
        {
            logo: "/img/module/icon_interaction.png",
            title: "人机交互"
        },
        {
            logo: "/img/module/icon_card2.png",
            title: "智能发袋"
        }
      ],
      params: [
        {
          label: "工作环境",
          value: "-18℃到55℃，最大相对湿度90%"
        },
        {
          label: "适用场合",
          value: "室内/室外"
        },
        {
          label: "额定功率",
          value: "100W"
        },
        {
          label: "空闲功率",
          value: "70W"
        },
        {
          label: "工作电源",
          value: "单相，AC220V-AC240V，50HZ"
        },
        {
          label: "通讯模式",
          value: "4G，全网通"
        },
        {
          label: "适用场景",
          value: "用于日常生活使用的垃圾袋发放并溯源"
        }
      ],
      plan: "/img/fd3.png"
    }
  }
};
