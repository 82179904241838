<template>
  <div>
    <div class="header">
      <img class="bg" src="/img/otherBg.png" alt />
      <div class="content">
        <div>
          <div class="title">项目案例</div>
          <div class="desc">
            用科技创造未来 /
            <br />循环和再生，让地球更年轻
          </div>
        </div>
      </div>
    </div>
    <!-- <div style="height: 250px"></div> -->
    <div class="container" id="recycleAll">
      <el-row>
        <el-col :span="8">
          <p class="abouttitle">
            RECYCLE
            <br />ALL
          </p>
          <div class="her"></div>
          <p class="abtitle">甬城分类</p>
        </el-col>
        <el-col :span="2"></el-col>
        <el-col :span="14">
          <p
            style="font-size: 16px; color: rgb(89, 87, 87); line-height: 32px;"
          >通过在居民小区、学校、机关企事业单位、商业综合体、农贸市场等公共场所布置智能回收箱，实现垃圾源头分类，用户通过手机APP(甬城分类)扫码、智能一卡通、人脸识别等方式开箱，根据智能屏幕引导，打开不同的回收仓，实现源头分类投放。以全品类智能回收箱为载体，完善溯源考核机制，全面取消居民厨余垃圾袋发放，利用新技术构建居民垃圾源头精准分类的考核、评分激励体系。本项目全程采用互联网+物联网结合的智慧化设备，通过重量传感器、区块链记账技术的应用，实时上传用户垃圾投递品类、重量并根据奖励规则给与有偿奖励，实现源头精准投放和分类质量精准评价。</p>
          <div class="img-list">
            <img src="/img/app1.png" alt />
            <img src="/img/app2.jpg" alt />
            <img src="/img/app3.png" alt />
            <img src="/img/app4.png" alt />
            <img src="/img/app5.png" alt />
          </div>
        </el-col>
      </el-row>
    </div>
    <hr>
    <div class="container" id="recycleFlb">
      <el-row>
        <el-col :span="8">
          <p class="abouttitle">
            RECYCLE
            <br />FLB
          </p>
          <div class="her"></div>
          <p class="abtitle">甬废清</p>
        </el-col>
        <el-col :span="2"></el-col>
        <el-col :span="14">
          <p style="font-size: 16px; color: rgb(89, 87, 87); line-height: 32px;">
            甬废清旨在构建全域化、信息化、规范化、高能级的预约收运处置模式，打通装修（大件）垃圾源头产生、收集、贮存、运输、利用和消纳处置全链条，实时数据同步显示在装修（大件）垃圾收运处置平台。凭借统一信息化平台，将全市小区、合规运输企业/车辆、处置场地纳入平台管理。
            司机抢单模式替代平台手动指派，系统综合考虑距离、司机服务评价、运输企业接单能力，锁定合适的部分司机允许抢单，解决人工调度时间长，匹配度不高问题。
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  watch:{
    $route:{
      immediate:true,
      handler(route) {
        // if(route.params.name) {
        //   let target = document.querySelector("#"+route.params.name)
        //   if(!target) return;
        //   console.log([target])
        // }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./header.scss";
.container {
  padding: 80px 0px 120px;
  max-width: 1500px;
  margin: 0 auto;
}
.abouttitle {
  font-size: 38px;
  color: #898989;
  font-family: DIN;
  line-height: 39px;
}
.her {
  width: 30px;
  height: 4.5px;
  background: #374aff;
  margin-top: 10px;
}
.abtitle {
  font-size: 33px;
  color: #221815;
  margin-top: 26px;
}
.img-list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  >img{
    width: 170px;
  }
}
</style>
