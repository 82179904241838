<template>
  <div class="product">
    <el-carousel
      trigger="click"
      :initial-index="swiperIndex"
      ref="swiper"
      @change="changeSwiper"
      :autoplay="false"
      height="49vw"
      direction="vertical"
      class="carousel"
    >
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <video muted @ended="next" ref="video">
          <source :src="item" type="video/mp4" />
        </video>
      </el-carousel-item>
    </el-carousel>

    <div class="body" ref="body">
      <div class="menus" :class="{ fixed: isFixed }">
        <div class="logo">
          <img src="/img/logo2.png" alt="" srcset="" />
        </div>
        <div class="scroller">
          <ul>
            <li
              @click="setTap(key)"
              :class="{ active: active == key }"
              v-for="(it, key, index) in t.menus"
              :key="index"
            >
              {{ it.name }}
            </li>
          </ul>
        </div>
      </div>

      <temp :t="t.menus[active]"></temp>
    </div>
  </div>
</template>

<script>
import data from "./data.js";
export default {
  components: {
    temp: () => import("./temp.vue")
  },
  watch: {
    $route() {
      if (this.$route.params.type) this.active = this.$route.params.type;
    }
  },
  data() {
    return {
      t: data,
      active: "hs",
      isFixed: false,
      swiperIndex: 0,
      list: [
        "https://wl-www.oss-cn-hangzhou.aliyuncs.com/%E6%90%AD%E6%8A%8A%E6%89%8B%E5%9E%83%E5%9C%BE%E5%88%86%E7%B1%BB%20%E6%88%90%E7%89%87.mp4",
        "https://wl-www.oss-cn-hangzhou.aliyuncs.com/%E5%B7%A8%E6%97%A0%E9%9C%B8%E6%99%BA%E8%83%BD%E6%9F%9C.mp4",
        "https://wl-www.oss-cn-hangzhou.aliyuncs.com/%E5%8E%A8%E4%BD%99%E5%92%8C%E5%85%B6%E4%BB%96%E5%9E%83%E5%9C%BE%E6%99%BA%E8%83%BD%E6%9F%9C.mp4",
        "https://wl-www.oss-cn-hangzhou.aliyuncs.com/%E7%93%B6%E7%B1%BB%E5%88%86%E6%8B%A3%E7%BA%BF.mp4"
      ]
    };
  },
  mounted() {
    if (this.$route.params.type) this.active = this.$route.params.type;
    document.querySelector("#app").addEventListener("scroll", this.scroll, false);
    window.onresize = this.scroll;

    this.$nextTick(() => {
      this.changeSwiper(0);
    });
  },
  beforeDestroy() {
    document.querySelector("#app").removeEventListener("scroll", this.scroll);
    window.onresize = null;
  },
  methods: {
    scroll() {
      let scrollTop = document.querySelector("#app").scrollTop;
      let dbsTop = this.getElementToPageTop(this.$refs.body);
      this.isFixed = scrollTop > dbsTop;
    },
    getElementToPageTop(el) {
      if (el.parentElement) {
        return this.getElementToPageTop(el.parentElement) + el.offsetTop;
      }
      return el.offsetTop;
    },
    changeSwiper(index) {
      this.$refs.video[this.swiperIndex].pause();
      this.swiperIndex = index;
      this.$refs.video[index].play();
    },
    next() {
      this.$refs.swiper.next();
    },
    setTap(key) {
      if(this.active == key) return;
      this.active = key
      document.querySelector("#app").scrollTop = window.innerWidth*0.49
    }
  }
};
</script>

<style scoped lang="scss">
video {
  width: 100vw;
  height: 100%;
  display: block;
  background-color: #000;
}
.body {
  position: relative;
}

.menus {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: #0d1733;
  .logo {
    width: 15.8rem;
    float: left;
    height: 11.6rem;
    position: relative;
    background-color: #0670d8;
    img {
      width: 7.8rem;
      height: 4rem;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
  }
  .scroller {
    width: 100%;
    padding-left: 15.8rem;
    box-sizing: border-box;
    & > ul {
      width: 100%;
      overflow-x: auto;
      list-style: none;
      background-color: #0d1733;
      white-space: nowrap;
      li {
        display: inline-block;
        padding: 4.6rem 5.8rem;
        font-size: 2rem;
        line-height: 2.3rem;
        letter-spacing: 1px;
        color: #ffffff;
        cursor: pointer;
      }
      .active {
        background-color: #2f3f6d;
      }
    }
  }
}

.fixed {
  position: fixed;
}
</style>
<style lang="scss">
.product {
  .el-carousel__arrow {
    display: none;
  }
  .el-carousel__indicators {
    margin-right: 5rem;
    .el-carousel__button {
      width: 4px;
      height: 7rem;
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 768px) {
    .el-carousel__indicators {
      margin-right: 1rem;
      .el-carousel__button {
        width: 4px;
        height: 15px;
        opacity: 0.8;
      }
    }
  }
}
</style>
