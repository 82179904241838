<template>
  <pre class="pre">
<h2>世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目</h2><h3>环境和社会影响评价公众参与第一次公示</h3>
    《世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目环境影响评价报告》和《 世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目社会影响评价报告 》、《 世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目社会尽职调查报告 》、《 世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目移民安置计划 》、《 世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目利益相关方参与计划 》已编制完成，现按世界银行要求，将世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目环境和社会影响评价有关信息予以第一次公示。该环境和社会影响评价报告用于世界银行项目审批需要，国内项目审批所需环评报告由其他单位另行编制。公示期间，宁波市生活垃圾分类指导中心接受来电、邮件等形式反映问题。联系单位：宁波市生活垃圾分类指导中心，联系电话：87952281，邮箱：nbljfl_pmo@163.com。
一、建设项目情况
（一）项目名称
    世行贷款中国塑料垃圾减量项目—宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目
（二）建设内容
    1、可回收物分拣中心建设工程，包括3座可回收物分拣中心：鄞州区可回收物分拣中心，选址于鄞州区姜山镇宁波绕城高速与明光路交叉口西南侧，占地面积约10亩，设计可回收物分拣能力170吨/日；江北区可回收物分拣中心，选址于江北区庄桥石材城南侧，占地面积约15亩，设计可回收物分拣能力100吨/日；北仑区可回收物分拣中心，选址于北仑区柴桥街道益捷货柜大湾堆场，占地面积约12.8亩，设计可回收物分拣能力85吨/日。建成后可满足周边超过300万居民所投放的可回收物垃圾的清运中转、分拣工作需求。
    2、塑料垃圾集中分选深加工厂建设工程：工程选址于宁波市海曙区洞桥镇洞桥循环产业基地2号地块，总用地面积约60亩。该工程用于对可回收垃圾中的塑料垃圾、混杂在其他垃圾中的塑料垃圾、家电拆解后产生的塑料垃圾以及装修垃圾中分离出来的塑料垃圾进行分选深加工。建成后将形成年处理4万吨塑料的产能。
    3、资源循环利用基地污水处理厂进厂管项目：工程选址位于宁波市海曙区洞桥镇宁波市固废处置中心园区内，建设内容包括全长约2千米的污水管线，以及配套的污水泵和在线水质监测设施。该项目主要用于接纳宁波市开诚餐厨垃圾处理厂，宁波市首创厨余垃圾厂，以及塑料垃圾集中分选深加工工程项目产生的废水，并将废水输送至宁波市资源循环利用基地污水处理厂进行处理。
    4、大件垃圾、建筑装修垃圾处置厂建设工程1：工程选址于宁波市鄞州区姜山镇宁波绕城高速与明光路交叉口西南侧，总占地面积约20亩。该工程用于对从生活垃圾中分离出来的的大件垃圾和装修垃圾进行处理、加工，使大件垃圾和装修垃圾得到再利用。建成后将形成年处理2万吨大件垃圾，10万吨装修垃圾，以及生产3480万块再生砖的处置能力。
二、环境和社会影响评价报告书征求意见稿的主要工作内容
    在对工程区域环境现状质量状况的调查基础上，对工程建设过程中和建设后可能造成的环境和社会影响进行分析、预测和评估，提出预防或者减轻不良环境和社会影响的对策和措施，并制定跟踪监测的方法和制度。
三、环境和社会影响报告书征求意见稿全文的网络链接及查阅纸质报告书的方式
    点击附件中链接进行查阅及下载。
四、征求意见的公众范围
    对建设项目所在地及周边区域的公众和有关单位征求意见。
五、公众意见表网络链接
    点击附件中链接进行查阅及下载。
六、提交公众意见表的方式和途径
    公众可以通过网络链接下载环境和社会影响报告书征求意见稿，通过信函、传真、电子邮件，将填写的公众意见表提交建设单位或评价机构，反映与建设项目环境和社会影响有关的意见和建议。公众提交意见时，应当提供有效的联系方式。
（一）建设单位及联系方式
    1、可回收物分拣中心建设工程建设单位：宁波搭把手生态数字科技有限公司
    联系人：张晓峰
    电话：18611179841
    2、塑料垃圾集中分选深加工厂建设工程建设单位：宁波海境资源循环科技有限公司
    联系人：陈 明
    电话：15395713870
    3、资源循环利用基地污水处理厂进厂管项目建设单位：宁波清源水环境服务有限公司
    联系人：王汉韬
    电话：15168540482
    4、大件垃圾、建筑装修垃圾处置厂建设工程1建设单位：宁波美境建筑固废循环科技有限公司
    联系人：杨天勇
    电话：13429322905
（二）承担评价工作的环境和社会影响评价机构的名称和联系方式
    评价单位：美华环境工程（上海）有限公司
    联系人：张 工
    电话：021-62370588
七、公众提出意见的起止时间
    请广大公众在公示期间通过信函、电话、传真或电子邮件的方式向建设单位、评价机构反映该项目环境保护方面的意见或建议。

<a
  v-for="i in fujianList"
  :key="i"
  :href="'/'+i"
  target="_blank"
>{{i}}</a>
  </pre>
</template>
<script>
export default {
  data() {
    return {
      fujianList: [
        "附件1-建设项目环境和社会影响评价公众意见表.docx",
        "附件2-世行贷款中国塑料垃圾减量项目-宁波城镇生活垃圾智慧分类、收集、循环利用示范项目 第三批子项目-环境影响评价报告.pdf",
        "附件3-世行贷款中国塑料垃圾减量项目-宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目社会影响评价报告.pdf",
        "附件4-世行贷款中国塑料垃圾减量项目-宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目社会尽职调查报告.pdf",
        "附件5-世行贷款中国塑料垃圾减量项目-宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目移民安置计划.pdf",
        "附件6-世行贷款中国塑料垃圾减量项目-宁波城镇生活垃圾智慧分类、收集、循环利用示范项目第三批子项目利益相关方参与计划.pdf",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.pre {
  font-size: 18px;
  line-height: 1.7;
  padding: 165px 70px;
  white-space: pre-wrap;
  h2 {
    text-align: center;
    padding: 0 70px;
    box-sizing: border-box;
  }
  h3 {
    text-align: center;
    padding: 0 70px;
    box-sizing: border-box;
  }
  a {
    display: block;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .pre {
    padding: 120px 30px;
    font-size: 16px;
    h2 {
      padding: 0 0px;
      font-size: 18px;
    }
    h3 {
      padding: 0 0px;
      font-size: 18px;
    }
    a {
      margin-bottom: 10px;
    }
  }
}
</style>